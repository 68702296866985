import React, { useState } from "react";
import MainSlider from "../Sliders/MainSlider";
import Reviews from "../Sliders/Reviews";
import s from "./FrontPage.module.css"
import vImg from "./../../img/reviews/v.png"
import aImg from "./../../img/reviews/a.png"
import cImg from "./../../img/reviews/c.png"
import alImg from "./../../img/reviews/al.png"
import FormFeedBack from "../Forms/FormFeedBack";
import prt from "./../../img/front-page/printscrin.png";
import speed from "./../../img/front-page/speedtest.png";
import handshake from "./../../img/front-page/handshake.png";
import SliderProjects from "../Sliders/SliderProjects";
import pr1 from "./../../img/projects/project1.png";
import pr2 from "./../../img/projects/project2.png";
import pr3 from "./../../img/projects/project3.png";
import pr4 from "./../../img/projects/project4.png";
import pr5 from "./../../img/projects/project5.png";
import pr6 from "./../../img/projects/project6.png";
import tch1 from "./../../img/front-page/photoshop.png";
import tch2 from "./../../img/front-page/illustrator.png";
import tch3 from "./../../img/front-page/figma.png";
import tch4 from "./../../img/front-page/html.png";
import tch5 from "./../../img/front-page/css.png";
import tch6 from "./../../img/front-page/js.png";
import tch7 from "./../../img/front-page/react.png";
import tch8 from "./../../img/front-page/wordpress.png";
import tch9 from "./../../img/front-page/sql.png";
import whyWeTopLine from "../../img/front-page/whywebot2.png";
import whyWeBotLine from "../../img/front-page/whywebot.png";
import formTop from "../../img/front-page/formtop.png";
import formBot from "../../img/front-page/formbot.png";
import achTop from "../../img/front-page/bigyelbacktop.png";
import achBot from "../../img/front-page/bigyelbackbot.png";
import formTop2 from "../../img/front-page/formTop3.png"
import formBot2 from "../../img/front-page/formBot3.png"
import FormItems from "../Forms/FormItems";
import Social from "../Social/Social";

const slides = [
    {
        imgUrl: vImg,
        title: "Valeriy",
        review: "HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit."   
    },
    {
        imgUrl: aImg,
        title: "Alice",
        review: "HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. "   
    },
    {
        imgUrl: alImg,
        title: "Alex",
        review: "HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. "   
    },
    {
        imgUrl: cImg,
        title: "Cristtian",
        review: "HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. "   
    },
    {
        imgUrl: vImg,
        title: "Valeriy",
        review: "HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit."   
    },
    {
        imgUrl: aImg,
        title: "Alice",
        review: "HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. "   
    },
    {
        imgUrl: alImg,
        title: "Alex",
        review: "HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. "   
    },
    {
        imgUrl: cImg,
        title: "Cristtian",
        review: "HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. "   
    },
    {
        imgUrl: vImg,
        title: "Valeriy",
        review: "HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit."   
    },
    {
        imgUrl: aImg,
        title: "Alice",
        review: "HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. "   
    },
    {
        imgUrl: alImg,
        title: "Alex",
        review: "HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. "   
    },
    {
        imgUrl: cImg,
        title: "Cristtian",
        review: "HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. HeaderLorem ipsum dolor, sit amet consectetur adipisicing elit. "   
    }
]
const slidesProjects = [
    {
        imgUrl: pr1,
        link: "#"
    },
    {
        imgUrl: pr2,
        link: "https://playspace.com.ua/"
    },
    {
        imgUrl: pr3,
        link: "#"
    },
    {
        imgUrl: pr4,
        link: "https://teeth.com.ua/"
    },
    {
        imgUrl: pr5,
        link: "#"
    },
    {
        imgUrl: pr6,
        link: "#"
    }
]
const itemsMas = [
    {
        name: "Landing page"
    },
    {
        name: "sait for bisness"
    },
    {
        name: "internet shop"
    },
    {
        name: "sait for key"
    },
    {
        name: "design for prog"
    },
    {
        name: "design for saits"
    },
    {
        name: "logo development"
    },
    {
        name: "firm products"
    },
    {
        name: "seo support"
    },
    {
        name: "sait support"
    },
    {
        name: "sait setting"
    },
]
const FrontPage = ({t})=>{
    const [showFormItems, setShowFormItems] = useState({
        show: false,
        value: ""
    })
    const [showWhite, setShowWhite] = useState()
    window.addEventListener("scroll", ()=>{
        let blockHeight = document.getElementById('whyWe')
        let blockHeightSec = document.getElementById('techno')
        if((window.scrollY > (blockHeight.offsetTop - blockHeight.offsetHeight/2) && window.scrollY < blockHeight.offsetTop + blockHeight.offsetHeight * 2) || (window.scrollY > blockHeightSec.offsetTop + blockHeightSec.offsetHeight/2)){
            setShowWhite(true)
        }else{
            setShowWhite(false)
        }
    })
    return(
        <div className={s.body}>
            <Social showWhite={showWhite}/>
            <div className={s.width85}>
                <MainSlider t={t}/>
                <div className={s.services__wrap}>
                    <div className={s.services__inner} id="services">
                        <h3 className={`${s.services__title} ${s.services__title__one}`}>{t("web development")}</h3>
                        <div className={s.sesrvices}>
                            <p onClick={()=>{
                                setShowFormItems({...showFormItems, show: true, value: "Landing page"})
                            }}>Landing page</p>
                            <p onClick={()=>{
                                setShowFormItems({...showFormItems, value: t("sait for bisness"), show: true})
                            }}>{t("sait for bisness")}</p>
                            <p onClick={()=>{
                                setShowFormItems({...showFormItems, show: true, value: t("internet shop")})
                            }}>{t("internet shop")}</p>
                            <p onClick={()=>{
                                setShowFormItems({...showFormItems, show: true, value: t("sait for key")})
                            }}>{t("sait for key")}</p>
                        </div>
                    </div>
                    <div className={s.services__inner}>
                        <h3 className={`${s.services__title} ${s.services__title__two}`}>{t("design")}</h3>
                        <div className={s.sesrvices}>
                            <p onClick={()=>{
                                setShowFormItems({...showFormItems, show: true, value: t("design for prog")})
                            }}>{t("design for prog")}</p>
                            <p onClick={()=>{
                                setShowFormItems({...showFormItems, show: true, value: t("design for saits")})
                            }}>{t("design for saits")}</p>
                            <p onClick={()=>{
                                setShowFormItems({...showFormItems, show: true, value: t("logo development")})
                            }}>{t("logo development")}</p>
                            <p onClick={()=>{
                                setShowFormItems({...showFormItems, show: true, value: t("firm products")})
                            }}>{t("firm products")}</p>
                        </div>
                    </div>
                    <div className={s.services__inner}>
                        <h3 className={`${s.services__title} ${s.services__title__three}`}>{t("additional services")}</h3>
                        <div className={s.sesrvices}>
                            <p onClick={()=>{
                                setShowFormItems({...showFormItems, show: true, value: t("seo support")})
                            }}>{t("seo support")}</p>
                            <p onClick={()=>{
                                setShowFormItems({...showFormItems, show: true, value: t("sait support")})
                            }}>{t("sait support")}</p>
                            <p onClick={()=>{
                                setShowFormItems({...showFormItems, show: true, value: t("sait setting")})
                            }}>{t("sait setting")}</p>
                        </div>
                    </div>
                    {
                        showFormItems.show ? 
                            <FormItems
                                value = {showFormItems.value}
                                close = {()=>{setShowFormItems({...showFormItems, show: false, value: showFormItems.value})
                            }}/>
                        : null
                    }
                </div>
            </div>

            <div className={s.width100}>
                <div className={s.why__we__top} id="whyWe">
                    <img className={s.why__we__top__image} src={whyWeTopLine} alt="Фонове зображення" />
                </div>
                <div className={s.why__we__wrap}>
                    <div className={s.why__we__in}>
                        <h2 className={s.why__we__title}>{t("why u need us")}</h2>
                        <div className={s.why__we__inner}>
                            <div className={s.why__we__back}>
                                <div className={s.why__we}>
                                    <div className={s.why__we__image}>
                                        <img className={s.why__we__im} src={prt} alt="Індивідуальність зображення" />
                                    </div>
                                    <h3 className={s.title}>{t("individuality")}</h3>
                                    <p>{t('why we one')}</p>
                                </div>
                            </div>
                            <div className={s.why__we__back}>
                                <div className={s.why__we}>
                                    <div className={s.why__we__image}>
                                        <img className={s.why__we__im} src={speed} alt="Швидкість зображення" />
                                    </div>
                                    <h3 className={s.title}>{t("speed")}</h3>
                                    <p>{t('why we two')}</p>
                                </div>
                            </div>
                            <div className={s.why__we__back}>
                                <div className={s.why__we}>
                                    <div className={s.why__we__image}>
                                        <img className={s.why__we__im} src={handshake} alt="Співпраця зображення" />
                                    </div>
                                    <h3 className={s.title}>{t("work together")}</h3>
                                    <p>{t('why we three')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.why__we__bot}>
                    <img className={s.why__we__bot__image} src={whyWeBotLine} alt="Фонове зображення" />
                </div>
            </div>


            <div className={s.width75}>
                <h2 className={s.mane__title}>{t("our port")}</h2>
                <SliderProjects  slidesProjects = {slidesProjects}/>
            </div>
            

            <div className={s.width100} id="formWrap">
                <div className={s.form__top}>
                    <img className={s.form__top__img} src={formTop2} alt="Фонове зображення" />
                </div>
                <div className={s.form__wrap}>
                    <div className={s.form__in}>
                        <div className={s.form__text}>
                            <div className={s.form__text__in}>
                                <h2 className={s.form__title}>{t("ideas for ur proj")}</h2>
                                <p className={s.form__text__text}>{t("from this form we start")}</p>
                            </div>
                        </div>
                        <div className={s.form__inner}>
                            <FormFeedBack t={t} />
                        </div>
                    </div>
                </div>
                <div className={s.form__bot}>
                    <img className={s.form__bot__img} src={formBot2} alt="Фонове зображення" />
                </div>
            </div>


            <div className={s.width75}>    
                <div className={s.slider__reviews}>
                    <h2 className={s.mane__title}>{t("our reviews")}</h2>
                    <Reviews  slides = {slides}/>
                </div>

                <div className={s.about__wrap} id="aboutUs">
                    <div className={s.about__innner}>
                        <h2 className={s.about__title}>{t("about it club")}</h2>
                        <p className={s.about__text}>{t('about us txt')}</p>
                    </div>
                    <div className={s.about__img}></div>
                </div>
            </div>

            <div className={s.width90}>
                <div className={s.achievement__top}>
                    <img src={achTop} alt="Фонове зображення" />
                </div>
                <div className={s.achievement__wrap}>
                    <div className={s.achievement__in}>
                        <div className={s.achievement__card}>
                            <p className={s.achievement__main__title}>5</p>
                            <h3 className={s.achievement__title}>{t('years')}</h3>
                        </div>
                        <div className={s.achievement__card}>
                            <p className={s.achievement__main__title}>100+</p>
                            <h3 className={s.achievement__title}>{t('good projects')}</h3>
                        </div>
                        <div className={s.achievement__card}>
                            <p className={s.achievement__main__title}>20+</p>
                            <h3 className={s.achievement__title}>{t('visible projects')}</h3>
                        </div>
                    </div>
                </div>
                <div className={s.achievement__bot}>
                    <img src={achBot} alt="Фонове зображення" />
                </div>
            </div>

            <div className={s.width75}>
                <h2 className={s.mane__title}>{t("technology")}</h2>
                <div className={s.techno} id="techno">
                    <div className={s.techno__card}>
                        <div className={s.card__img}>
                            <img src={tch1} alt="Abode Photoshop логотип" />
                        </div>
                        <div className={s.card__black}>
                            <h3 className={s.card__text}>Abode<br></br>Photoshop</h3>
                        </div>
                    </div>

                    <div className={s.techno__card}>
                        <div className={s.card__img}>
                            <img src={tch2} alt="Abode Illustrator логотип" />
                        </div>
                        <div className={s.card__black}>
                            <h3 className={s.card__text}>Abode<br></br>Illustrator</h3>
                        </div>
                    </div>

                    <div className={s.techno__card}>
                        <div className={s.card__img}>
                            <img src={tch3} alt="Figma логотип" />
                        </div>
                        <div className={s.card__black}>
                            <h3 className={s.card__text}>Figma</h3>
                        </div>
                    </div>

                    <div className={s.techno__card}>
                        <div className={s.card__img}>
                            <img src={tch4} alt="HTML логотип" />
                        </div>
                        <div className={s.card__black}>
                            <h3 className={s.card__text}>HTML</h3>
                        </div>
                    </div>

                    <div className={s.techno__card}>
                        <div className={s.card__img}>
                            <img src={tch5} alt="CSS логотип" />
                        </div>
                        <div className={s.card__black}>
                            <h3 className={s.card__text}>CSS</h3>
                        </div>
                    </div>

                    <div className={s.techno__card}>
                        <div className={s.card__img}>
                            <img src={tch6} alt="JS логотип" />
                        </div>
                        <div className={s.card__black}>
                            <h3 className={s.card__text}>JS</h3>
                        </div>
                    </div>

                    <div className={s.techno__card}>
                        <div className={s.card__img}>
                            <img src={tch7} alt="React JS логотип" />
                        </div>
                        <div className={s.card__black}>
                            <h3 className={s.card__text}>React JS</h3>
                        </div>
                    </div>

                    <div className={s.techno__card}>
                        <div className={s.card__img}>
                            <img src={tch8} alt="WordPress логотип" />
                        </div>
                        <div className={s.card__black}>
                            <h3 className={s.card__text}>WordPress</h3>
                        </div>
                    </div>

                    <div className={s.techno__card}>
                        <div className={s.card__img}>
                            <img src={tch9} alt="SQL логотип" />
                        </div>
                        <div className={s.card__black}>
                            <h3 className={s.card__text}>SQL</h3>
                        </div>
                    </div>

                    <div className={s.black__card}>
                        <h3 className={s.card__text}>Native code</h3>
                    </div>
                </div>
            </div>
        </div>
        
           
    )
}
export default FrontPage;