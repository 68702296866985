import React, { useEffect, useState } from "react";
import s from "./Reviews.module.css";
const Reviews = ({slides}) =>{
    const [slider, setSlider] = useState({
        sliderPosition: 0,
        slideNow: 0,
        marginLeft: 0,
        step: 25,
        dotActive:0,
        amountOnPage: 0,
        helpWithMove: 25
    })
    // useEffect(()=>{
    //     if(window.innerWidth < 600){
    //         setSlider({...slider, amountOnPage: 100, step: 100, helpWithMove: 100})
    //     }
    //     else{
    //         setSlider({...slider, amountOnPage: 25})
    //     }
    // }, [])
    // window.addEventListener("resize", (event) => {
    //     if(window.innerWidth < 600){
    //         setSlider({...slider, amountOnPage: 100, step: 100, helpWithMove: 100})
    //     }
    //     else{
    //         setSlider({...slider, amountOnPage: 25})
    //     }
    // });

    const [isOneSlide, setIsOneSlide] = useState(false);

    useEffect(()=>{
        if(window.innerWidth < 600){
            setOneSlide()
        }
        else{
            setTwoSlides()
        }

    }, [])
    window.addEventListener("resize", (event) => {
        if(window.innerWidth < 600){
            if (!isOneSlide) {
                setOneSlide()
            }
        }
        else{
            if (isOneSlide) {
                setTwoSlides()
            }
        }
    });

    function setTwoSlides() {
        setSlider({...slider, amountOnPage: 25,  step: 25, helpWithMove: 25})
        setIsOneSlide(false)
    }

    function setOneSlide() {
        setSlider({...slider, amountOnPage: 100, step: 100, helpWithMove: 100})
        setIsOneSlide(true)
    }

    return(
        <div className={s.wrap}>
            <div className={`${s.arrow} ${s.arrow__left}`} onClick={()=>{
                let go = slider.sliderPosition + slider.step;
                if(go > 0) return;
                let slN = slider.slideNow - 1;
                if(slN % 4 == 0){
                    setSlider({...slider, dotActive:slN, sliderPosition: go, slideNow:slN});
                }else{
                    setSlider({...slider, sliderPosition: go, slideNow:slN})
                }
            }}></div>
            <div className={s.inner}>
                <div className={s.slider} style={{
                    gridTemplateColumns: `repeat(${slides.length}, ${slider.amountOnPage}%)`,
                    marginLeft: `${slider.sliderPosition}%`
                }}>
                    {
                        slides.map((item, index)=>{

                            return(
                                <div key={index} className={s.slide}>
                                    
                                    <div className={`${s.slide__in} ${index%2==0 ? s.shadow : s.color}`}>
                                        <div className={s.item}>
                                            <div className={s.item__img}>
                                                <img className={s.img} src={item.imgUrl} alt={item.title} />
                                            </div>
                                            <div className={s.item__title}>
                                                <h3 className={s.title}>{item.title}</h3>
                                            </div>
                                            <div className={s.item__text}>
                                                <p className={s.text}>{item.review}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={s.dots__wrap}>
                    <div className={s.dots}>
                        {slides.map((item, index)=>{
                            return index % 4 == 0 || index === 0 ?(
                                <div key={index + 100} className={slider.dotActive == index ? `${s.dot} ${s.dot__active}` : s.dot}  onClick={()=>{
                                    let slN = index;
                                    let slP = -index * slider.step;
                                    setSlider({...slider, slideNow:slN, sliderPosition: slP, dotActive:slN})
                                }}>
                                </div>
                            ): null
                        })}
                    </div>
                </div>
            </div>
            <div className={`${s.arrow} ${s.arrow__right}`} onClick={()=>{
                let go = slider.sliderPosition - slider.step;
                if(go < -(slides.length * slider.helpWithMove) + 100) return;
                let slN = slider.slideNow + 1;
                if(slN % 4 == 0){
                    setSlider({...slider, dotActive:slN, sliderPosition: go, slideNow:slN});
                }else{
                    setSlider({...slider, sliderPosition: go, slideNow:slN})
                }
            }}></div>
        </div>
    )
}
export default Reviews;