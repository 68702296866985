import React, {useState} from "react";
import s from "./BurgerMenu.module.css";
import viber from "../../img/icons8-viber-50.png"
import facebook from "../../img/icons8-facebook-50.png"
import telegram from "../../img/icons8-телеграмма-app-50.png"
import instagram from "../../img/icons8-instagram-100.png"
import Menu from "../Header/Menu/Menu";
import chrest from "../../img/front-page/chrest.png"

function BurgerMenu({t, setActive}){
    return(
        <div className={s.main__wrap}>
       
            <div className={s.close__wrap} onClick={()=>{
                setActive()
            }}></div>    
            <div className={s.wrap}>
                <div className={s.in}>
                    <svg className={s.chrest} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" color="#ffffff" height="25" viewBox="0 0 50 50" onClick={()=>{
                        setActive()
                    }}><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path></svg>
                    <Menu t={t}/>
                    <div className={s.social}>
                        <a href="viber://chat/?number=%2B380932080760"><img src={viber} alt="Viber" /></a>
                        <a href="https://www.facebook.com/itclubkyiv"><img src={facebook} alt="Facebook" /></a>
                        <a href="https://t.me/+380932080760"><img src={telegram} alt="Telegram" /></a>
                        <a href="https://www.instagram.com/itclubkyiv/"><img src={instagram} alt="Instagram" /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BurgerMenu;
