import React from "react";
import s from "./../Header.module.css";
const Menu = ({t})=>{
    return(
        <nav className={s.nav}>
            <ul className={s.ul}>
                <li className={s.item}>
                    <a href="#">{t('main')}</a>
                </li>
                <li>
                    <a href="#services">{t('development')}</a>
                </li>
                <li>
                    <a href="#footer">{t('contacts')}</a>
                </li>
                <li>
                    <a href="#aboutUs">{t('about us')}</a>
                </li>
            </ul>
        </nav>
    )
}
export default Menu;