import React from "react";
import { useState } from "react";
import i18n from "../../../i18n";
import s from "./../Header.module.css";

const Language = ()=>{
const [lang, setLang] = useState('ua')
    return(
        <div className={s.lang__wrap}>
            <div onClick={()=>{
                 i18n.changeLanguage('ua')
                 setLang('ua')
            }} className={`${s.lang__item} ${lang === 'ua' ? s.lang__item__push : null}`}>UA</div>
            <div onClick={()=>{
                 i18n.changeLanguage('en')
                 setLang('en')
            }} className={`${s.lang__item} ${lang === 'en' ? s.lang__item__push : null}`}>EN</div> 
        </div>
    )
}
export default Language;