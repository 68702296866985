import React, { useEffect, useState } from "react";
import s from "./SliderProjects.module.css";

const SliderProjects = ({slidesProjects})=>{
    const [slider, setSlider] = useState({
        sliderPosition: 0,
        slideNow: 0,
        marginLeft: 0,
        step: 50,
        dotActive:0,
        amountOnPage: 0,
        helpWithMove: 50
    })

    const [isOneSlide, setIsOneSlide] = useState(false);

    useEffect(()=>{
        if(window.innerWidth < 600){
            setOneSlide()
        }
        else{
            setTwoSlides()
        }

    }, [])

    window.addEventListener("resize", (event) => {
        if(window.innerWidth < 600){
            if (!isOneSlide) {
                setOneSlide()
            }
        }
        else{
            if (isOneSlide) {
                setTwoSlides()
            }
        }
    });

    function setTwoSlides() {
        setSlider({...slider, amountOnPage: 50,  step: 50, helpWithMove: 50})
        setIsOneSlide(false)
    }

    function setOneSlide() {
        setSlider({...slider, amountOnPage: 100, step: 100, helpWithMove: 100})
        setIsOneSlide(true)
    }

    return(
        <div className={s.wrap}>
            <div className={`${s.arrow} ${s.arrow__left}`} onClick={()=>{
                let go = slider.sliderPosition + slider.step;
                if(go > 0) return;
                let slN = slider.slideNow - 1;
                if(slN % 2 == 0){
                    setSlider({...slider, dotActive:slN, sliderPosition: go, slideNow:slN});
                }else{
                    setSlider({...slider, sliderPosition: go, slideNow:slN})
                }
            }}></div>

            <div className={s.inner}>
                <div className={s.slider} style={{
                    gridTemplateColumns: `repeat(${slidesProjects.length}, ${slider.amountOnPage}%)`,
                    marginLeft: `${slider.sliderPosition}%`
                }}>
                    {slidesProjects.map((item, index)=>{
                        return(
                            <div key={index} className={s.item}>
                                <a className={s.item__link} href={item.link}></a>
                                <img className={s.img} src={item.imgUrl} alt="Клієнти зображення" />
                            </div>
                        )
                        })
                    }
                </div>
            </div>

            <div className={`${s.arrow} ${s.arrow__right}`} onClick={()=>{
                let go = slider.sliderPosition - slider.step;
                if(go < -(slidesProjects.length * slider.helpWithMove) + 100) return;
                let slN = slider.slideNow + 1;
                if(slN % 2 == 0){
                    setSlider({...slider, dotActive:slN, sliderPosition: go, slideNow:slN});
                }else{
                    setSlider({...slider, sliderPosition: go, slideNow:slN})
                }
            }}></div>
        </div>
    )
}
export default SliderProjects;