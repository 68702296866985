import React, { useState } from "react";
import s from "./Header.module.css";
import logoImg from "./../../img/logo.png";
import Language from "./Language/Language";
import Burger from "./Burger/Burger";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
const Header = ({t})=>{
    const [activeMenu,setActiveMenu] = useState(false)
    return(
        <header className={s.header__wrap}>
            <div className={s.header}>
                <div className={s.logo__wrap}>
                    <a href="/"><img src={logoImg} alt="Логотип IT Club Studio" /></a>
                </div>
                <div className={s.links__wrap}>
                    
                    <Language t={t}/>
                    <div className={s.line}></div>
                    <div className={s.phone}>
                        <a href="tel: +380 93 208 07 60">+380932080760</a>
                    </div>
                    <Burger active = {activeMenu} setActive = {()=>{setActiveMenu(!activeMenu)}}/>
                </div>
            </div>
            {activeMenu ? <BurgerMenu setActive = {()=>{setActiveMenu(!activeMenu)}} t={t}/> : null}
        </header>
    )
}
export default Header;