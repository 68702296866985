import React from "react";
import s from "./MainSlider.module.css";
import banerImg from "./../../img/front-page/banerImg.png"
const MainSlider = ({t})=>{
    return(
        <div className={s.main__slider__wrap}>
            <div className={s.main__slider__inner}>
                <div className={s.main__slider__text}>
                    <div className={s.main__slider__text__inner}>
                        <div className={s.main__slider__black__line}>
                            <h1 className={s.main__slider__title}>{t("create sait")}</h1>
                        </div>
                        <p>{t('main slider text')}</p>
                        <a className={s.main__slider__but} href="#formWrap">{t("make an order")}</a>
                    </div>
                </div>
                <div className={s.main__slider__black}>
                    <div className={s.main__slider__yel}>
                        <img className={s.main__slider__im} src={banerImg} alt="Фонове зображення" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MainSlider;