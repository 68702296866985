import React from "react";
import s from "./Footer.module.css";
import footerLogo from "../../img/footerlogo.png"
import viber from "../../img/icons8-viber-50.png"
import facebook from "../../img/icons8-facebook-50.png"
import telegram from "../../img/icons8-телеграмма-app-50.png"
import instagram from "../../img/icons8-instagram-100.png"


const Footer = ({t})=>{
    return(
        <div className={s.footer__wrap} id="footer">
            <div className={s.footer__in}>
                <div className={s.footer__logo}>
                    <img src={footerLogo} alt="Логотип" />
                </div>
                <div className={s.footer__inner}>
                    <div className={s.footer__card}>
                        <h3>{t("contacts footer")}</h3>
                        <div className={s.footer__card__in}>
                            <a href="tel: +380 93 208 07 60">+380 (93) 208 07 60</a>
                            <a href="tel: +380 95 666 30 75">+380 (95) 666 30 75</a>
                            <a href="mailto: studioitclub@gmail.com">studioitclub@gmail.com</a>
                        </div>
                    </div>
                    <div className={s.footer__map__main}>
                        <iframe className={s.footer__map} src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d4502.7342883297515!2d30.594946262896652!3d50.45036891760305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sua!4v1676038826660!5m2!1sru!2sua"></iframe>
                    </div>
                    <div className={s.footer__card}>
                        <div className={s.footer__card__in__dop}>
                            <h3>{t("adres")}</h3>
                            <p>{t("adres full")}<br></br>{t("adres full2")}</p>
                            <h3>{t("social")}</h3>
                            <div className={s.footer__soc}>
                                <a href="viber://chat/?number=%2B380932080760"><img src={viber} alt="Viber" /></a>
                                <a href="https://www.facebook.com/itclubkyiv"><img src={facebook} alt="Facebook" /></a>
                                <a href="https://t.me/+380932080760"><img src={telegram} alt="Telegram" /></a>
                                <a href="https://www.instagram.com/itclubkyiv/"><img src={instagram} alt="Instagram" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.copyright__wrap}>
                    <p className={s.copyright}>Copyright © IT Club Studio, 2023 </p>
                    <span>{t("copyright")}</span>
                </div>
            </div>
        </div>
    )
}
export default Footer;
