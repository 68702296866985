import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Content from './components/Content/Content';
import Footer from './components/Footer/Footer';


import { useTranslation } from 'react-i18next';
import useLocalStorage from './hooks/use-lokalstorage';

function App() {
  const {t} = useTranslation();
  const [language, setLanguage] = useLocalStorage('language', 'ua');

  return (
    <div  className='App'>
      <Header t={t}/>
      <Content t={t}/>
      <Footer t={t}/>
    </div>
  );
}

export default App;
