import React, { useState } from "react";
import s from "./Form.module.css";
import { apiResponse } from "../functions/api";
function checkStr(str, min){
    if(str.length < min) return true
    else return false
}
const FormFeedBack = ({t}) =>{
    const [form, setForm] = useState({
        name:{require: true, error: false, value:"", errorMes: "", minStr: 3},
        phone:{require: true, error: false, value:"", errorMes: "", minStr: 10},
        email:{require: true, error: false, value:"", errorMes: "", minStr: 8},
        idea:{require: true, error: false, value:"", errorMes: "", minStr: 10},
        item:"",
        checkbox: false
    })
    function sendForm(event){
        event.preventDefault();
        apiResponse({...form},"https://studio.itclub.in.ua/send-email.php").then((response)=>{
            alert(response);
           // window.location.reload();
        })
    }
    return(
        <div className={s.wrap}>
            <form onSubmit={sendForm} className={s.form}>
                <div className={s.inner}>
                    <div className={s.line}>
                        <div className={s.two}>
                            <div>
                                <input value={form.name.value} required type="text" name="" id="" placeholder={t("ur name")} onChange={(event)=>{
                                    let newValue = event.target.value.replaceAll("  "," ");
                                    let errorNew = false;
                                    let errorMesNew = "";
                                    if(checkStr(newValue, form.name.minStr)){
                                        errorNew = true;
                                        errorMesNew = `${t("not enough letters")}`
                                    }
                                    setForm({...form, name:{...form.name, value: newValue, error: errorNew, errorMes: errorMesNew}})
                                }} />
                                {form.name.error ? <p className={s.error}>{form.name.errorMes}</p> : null}
                            </div>
                            <div>
                                <input value={form.phone.value} required type="text" name="" id="" placeholder={t("ur phone")} onChange={(event)=>{
                                    let newValue = event.target.value.replaceAll("  "," ");
                                    let errorNew = false;
                                    let errorMesNew = "";
                                    if(checkStr(newValue, form.phone.minStr)){
                                        errorNew = true;
                                        errorMesNew = `${t("not enough letters")}`
                                    }
                                    setForm({...form, phone:{...form.phone, value: newValue, error: errorNew, errorMes: errorMesNew}})
                                }}/>  
                                {form.phone.error ? <p className={s.error}>{form.phone.errorMes}</p> : null}  
                            </div>
                        </div>
                    </div>
                    <div className={s.line}>
                        <div className={s.one}>
                            <input value={form.email.value} required type="email" name="" id="Email" placeholder="Email" onChange={(event)=>{
                               let newValue = event.target.value.replaceAll("  "," ");
                               let errorNew = false;
                               let errorMesNew = "";
                               if(checkStr(newValue, form.email.minStr)){
                                   errorNew = true;
                                   errorMesNew = `${t("not enough letters")}`
                               }
                               setForm({...form, email:{...form.email, value: newValue, error: errorNew, errorMes: errorMesNew}})
                            }}/>    
                            {form.email.error ? <p className={s.error}>{form.email.errorMes}</p> : null}  
                        </div>
                    </div>
                    <div className={s.line}>
                        <div className={s.one}>
                            <textarea value={form.textarea} name="" id="" cols="30" rows="4" placeholder={t("ur idea")} onChange={(event)=>{
                                let newValue = event.target.value.replaceAll("  "," ");
                                let errorNew = false;
                                let errorMesNew = "";
                                if(checkStr(newValue, form.idea.minStr)){
                                    errorNew = true;
                                    errorMesNew = `${t("not enough letters")}`
                                }
                                setForm({...form, idea:{...form.idea, value: newValue, error: errorNew, errorMes: errorMesNew}})
                            }}></textarea>
                            {form.idea.error ? <p className={s.error}>{form.idea.errorMes}</p> : null} 
                        </div>
                    </div>
                    <div className={s.line}>
                        <div className={s.checkbox}>
                            <label htmlFor="" className={s.label}>
                                <input checked={form.checkbox} required type="checkbox" name="" id="" onChange={(event)=>{
                                    setForm({...form, checkbox: event.target.checked})
                                }}/>
                                <div className={s.input__text}>
                                    <span className={s.label__text} onClick={()=>{
                                        setForm({...form, checkbox: !form.checkbox})
                                    }}>{t("agree with")}</span>
                                    <a href="#">{t("politic")}</a>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={s.line}>
                        <button className={s.btn}>{t("make an order")}</button>
                    </div>    
                </div> 
            </form>
        </div>
    );
}
export default FormFeedBack;