import axios from "axios";

export async function apiResponse(obj, url){
    let ror = {
        name:obj.email.value,
        phone:obj.phone.value,
        email:obj.email.value,
        idea:obj.idea.value,
        item:obj.item,
    }
    return await axios({
        url: url,
        method: "POST",
        header: {'application/x-www-form-urlencoded': 'application/json;charset=utf-8'},
        data: JSON.stringify(ror),
    })
    .then((data)=>{
        console.log("Received data: ", data.data);
        return (data.data)
    })
    .catch((error)=>{
        throw error;
    })
}